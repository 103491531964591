import React from "react"

import MainNavBar from "../mainNavBar"
import CaptionPrivacy from "./captionPrivacy"

const HeaderPrivacy = ({ siteTitleContact }) => (
  <header className="headerPrivacy">
    <a href="tel:18003582468" className="topRightPhone">1-800-358-2468</a>
    <MainNavBar />
    <CaptionPrivacy />
  </header>
)

export default HeaderPrivacy
